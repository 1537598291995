import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { BaseRequestService } from 'app/_services/base.service';

@Component({
    selector       : 'fuse-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;
    init:boolean = true
    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public totalAlerts=0
    /**
     * Constructor
     */
    constructor(
        private api:BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        // this.getAlerts()
        this.getAlerts()
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    getAlerts(): void {
        this.callAlertsApi().then(() => {
          setTimeout(() => {
            this.getAlerts();
          }, 300000);
        });
      }
      
      /**
       * On destroy
       */
      callAlertsApi() {
        const user = JSON.parse(localStorage.getItem('user'));
        var q: any = { skip: 0, limit: 1 };
      
        if (user && user.role && (user.role == 'RoDealer' || user.role == 'RoManager' || user.role == 'CustomerAttendant')) {
          q.q = {
            "query": {
              "bool": {
                "must": [
                  { "match": { "_type_.keyword": 'alerts' } },
                  { "match": { "status.keyword": 'Open' } },
                  { "match": { "ro_master_ref.ro_code.keyword": user.ro_data[0] } }
                ]
              }
            }
          };
        } else {
          q.q = {
            "query": {
              "bool": {
                "must": [
                  { "match": { "_type_.keyword": 'alerts' } },
                  { "match": { "status.keyword": 'Open' } },
                ]
              }
            }
          };
        }
      
        return new Promise<void>((resolve) => {
          this.api.doRequest(`/api/alerts`, 'get', null, q).subscribe((result: any) => {
            if (result && result.data && result.data.length > 0) {
              this.totalAlerts = result.total;
            } else {
              this.totalAlerts = 0;
            }
            this._changeDetectorRef.markForCheck();
            resolve();
          }, error => {
            this._changeDetectorRef.markForCheck();
            resolve();
          });
        });
      }
      
      // Call getAlerts() initially to start the loop
      
            

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.init = false
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
