<div class="not-prose notdownloadReportsdsr relative rounded-xl overflow-auto bg-blue-100">
    <div class="flex flex-row flex-wrap  pl-8 pr-4 pb-2 flex items-center">
        <mat-form-field class="pr-4" appearance="fill">
            <mat-label class="text-blue-800">Month and Year</mat-label>
            <input class="text-blue-800" matInput [matDatepicker]="dp" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
          <mat-form-field class="pr-4" appearance="fill">
            <mat-select class="text-blue-800 tankid" name="product" id="product" [(ngModel)]="product_code"  placeholder="Product code">
                <mat-option [value]="tank.product_code" *ngFor="let tank of product;">{{producthash[tank.product_code]? producthash[tank.product_code].product_name: tank.product_code}}</mat-option>
                </mat-select>
            <mat-label class="text-blue-800">Product Code</mat-label>
            <mat-icon
            class="icon-size-5"
            matSuffix
            matTooltip="Please enter the product code"
            [svgIcon]="'feather:info'"></mat-icon> 
            <mat-error>
                Please enter the product code
            </mat-error>
        </mat-form-field>
        <button id="gbtnactiondefoir" class="pr-4" (click)="fetchRecord()" matTooltip="Search Data"  mat-raised-button>
            GO
        </button>
    </div>

      <div class="tableFixHead ">
      <div style="background-position:10px 10px" class="inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"></div>
      <div class="relative rounded-xl">
    <div class="shadow-sm scrollclass overflow-x-auto mx-6 mb-6 mt-4 dsrreportdatain" id="dsrreportdatain" *ngIf="monthData && monthData.tanks && monthData.nozzles" style="max-height: 70vh !important;">
      <table class="border-collapse table-auto w-full text-md dsrreportdatainx" id="dsrreportdatainx">
        <col class="p-2">
        <colgroup class="p-2" [attr.span]="monthData.tanks.length"></colgroup>
        <col class="p-2">
        <colgroup class="p-2" [attr.span]="monthData.nozzles.length"></colgroup>
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">
        <col class="p-2">

        <tr>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-4 pt-1 pb-3 text-blue-600 text-center" rowspan="2">Day</th>
          <ng-container  *ngFor="let tan of monthData.tanks;">
            <th  class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-4 pt-1 pb-3 text-blue-600 text-center" colspan="2" scope="colgroup">Tank: {{tan.tank}}</th>
          </ng-container>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Tank Dip Sales (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" [attr.colspan]="monthData.nozzles.length" scope="colgroup">Global Nozzle Nos (Opening Totalizer)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Totalizer Sale Excl Testing (Ltr)<span class="font-bold"> D </span></th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Cumulative Totalizer Sale (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Transaction Sale Excl Testing (Ltr)<span class="font-bold"> F </span></th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Cumulative Transaction Sale (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">(D-F) Ltr</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Testing (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">2T Oil (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Loss/Gain as per Totalizer (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Loss/Gain as per Transaction (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Cumulative Loss/Gain as per Totalizer
              (Ltr)</th>
          <th class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" rowspan="2" scope="col">Cumulative Loss/Gain as per
              Transaction (Ltr)</th>

        </tr>
        <tr>
            <ng-container  *ngFor="let tan of monthData.tanks;">
                <th id=fixx class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" scope="col">Opening Stock (Ltr)</th>
                <th id=fixx  class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" scope="col">Received (Ltr)</th>      
            </ng-container>
            <ng-container  *ngFor="let noz of monthData.nozzles;">
                <th id=fixx  class="border-b border-l border-r border-blue-100 bg-blue-200 font-medium p-4 pl-6 pr-6 pt-1 pb-3 text-blue-600 text-center" scope="col">{{noz.nozzle_id}}</th>
            </ng-container>
        </tr>
       
        <ng-container *ngFor="let rec of data">
        <tr class="bg-white " id="isscrolled">
            <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">{{rec.day}}</td>
            <ng-container *ngFor="let tan of rec.tanks">
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{tan.start_product_volume |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{tan.decant_volume |  number: '1.0-2'}}
                </td>
            </ng-container>
            <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">{{rec.total_tank_sales |  number: '1.0-2'}}</td>
            <ng-container *ngFor="let noz of rec.nozzles">
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{noz.start_totalizer_volume |  number: '1.0-2'}}
                </td>
            </ng-container>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.profit_totalizer_d |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.cumulative_totalizer |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.profit_sales_f |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.cumulative_sales |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.d_minus_f |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.testing_totalizer |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    0
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.gain_per_totalizer |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.gain_per_transaction |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.cumulative_gain_per_totalizer |  number: '1.0-2'}}
                </td>
                <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-blue-800 ">
                    {{rec.cumulative_gain_per_transaction |  number: '1.0-2'}}
                </td>

        </tr>
        </ng-container>
      </table>
      </div>
      </div>
      </div>
      </div>


