import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { UserComponent } from 'app/layout/common/user/user.component';
import { MaterialModule } from 'app/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { ShiftComponent } from './shift/shift.component';

@NgModule({
    declarations: [
        UserComponent,
        ShiftComponent
    ],
    imports     : [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        CommonModule,
        MatSidenavModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,
        MatExpansionModule,
        ReactiveFormsModule, MaterialModule,
        DirectivesModule
    ],
    exports     : [
        UserComponent
    ]
})
export class UserModule
{
}
