import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseRequestService } from 'app/_services/base.service';
import { ModalService } from 'app/_services/modal.service';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { MyToastrService } from 'app/_services/toastr.service';
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShiftComponent implements OnInit,  OnDestroy
{   logout='No'
    shiftslist: any=[];
    ro_code=''
    dulist: any=[];
    nozzlelist: any=[{}];
    userdetails: any;
    selectedShift:any={};
    selectedDu:any=[];
    selectedNozzle:any=[{}];
    nozzleref: any=[];
    postData:any={};
    composeForm: UntypedFormGroup;
    copyFields: { cc: boolean; bcc: boolean } = {
        cc : false,
        bcc: false
    };
    quillModules: any = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{align: []}, {list: 'ordered'}, {list: 'bullet'}],
            ['clean']
        ]
    };
  shiftslistref: any=[];
  endshift:string=''
  validshifts: any=[];

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<ShiftComponent>,
        private _formBuilder: UntypedFormBuilder,
        public modalService:ModalService,
        private api:BaseRequestService,
        public toast:MyToastrService,
        private _changeDetectorRef: ChangeDetectorRef,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Create the form
        this.composeForm = this._formBuilder.group({
            to     : ['', [Validators.required, Validators.email]],
            cc     : ['', [Validators.email]],
            bcc    : ['', [Validators.email]],
            subject: [''],
            body   : ['', [Validators.required]]
        });
        const data = JSON.parse(localStorage.getItem('user'))
        this.ro_code= localStorage.getItem('loggedinro')
        this.getuser();
    }

    getuser(){
      this.userdetails = JSON.parse(localStorage.getItem('user'))
        this.shiftslistref = this.userdetails.shift_master_ref
              this.postData.user_ref={
                "id": this.userdetails._id,
                "name": this.userdetails.name,
                "role": this.userdetails.role,
                "contact_number": this.userdetails.contact_number,
                "email": this.userdetails.email,
                "status": this.userdetails.status,
              }
              if(this.ro_code){
                this.fetchlog()
              }
          // })
          this._changeDetectorRef.markForCheck();
      // }
  // })

  }
  /**
   * On destroy
   */
   fetchdata(){
    this.selectedDu=[];
    this.selectedNozzle=[];
    this.dulist=[];
    this.nozzlelist=[];
    this.getshifts();
    this.getro();
    this.getdu();
    this.getNozzle();
   }

   async fetchlog(){
    var q:any = {skip:0, limit:1, q:{'query':{"bool":{"must":[{"match":{"_type_.keyword":"shift_log"}},{"match":{"status":"Open"}},{"match":{"ro_master_ref.ro_code.keyword":this.ro_code}},{"match":{"user_ref.id.keyword":this.userdetails._id}}]}}}}
    this.api.doRequest(`/api/shift_log`, 'get', null, q).subscribe((result: any) => {
      if(result && result.data && result.data.length>0){ 
        this.postData=result.data[0]
        this.endshift= 'Yes';
        this.postData.selectedDU=''
        result.data[0].du_master_ref.forEach(ele =>{
          if(this.postData.selectedDU && this.postData.selectedDU.length>0){
            this.postData.selectedDU= `${this.postData.selectedDU},${ele.du_id}`
          }
          else{
            this.postData.selectedDU= ele.du_id
          }
        })
        this.postData.selectedNozzle=[]
        result.data[0].nozzle_master_ref.forEach(ele =>{
          if(this.postData.selectedNozzle && this.postData.selectedNozzle.length>0){
            this.postData.selectedNozzle= `${this.postData.selectedNozzle},${ele.nozzle_id}`
          }
          else{
            this.postData.selectedNozzle= ele.nozzle_id
          }
        })
        this.selectedShift=result.data[0].user_ref.shift;
        this._changeDetectorRef.markForCheck();
      }
      else{
        this.endshift='No'
        this.fetchdata();
      }
    })
   }

   getshifts(){
    this.shiftslist= this.shiftslistref.filter(x => (!x.ro_master_ref || x.ro_master_ref== null || x.ro_master_ref.ro_code === this.ro_code))
    const hourNow =moment().format('H:mm')
    this.validshifts=[];
    var diff = 0;
    this.shiftslist.forEach( ele =>{
      const start = JSON.parse(JSON.stringify(ele.shift_open_time.split(':')))
      const end = JSON.parse(JSON.stringify(ele.shift_close_time.split(':')))
      if(end[0]<start[0]){
        end[0] = Number(24) + Number(end[0])
      }
      const now = hourNow.split(':')
      if(Number(now[0]) >= Number(start[0]) && Number(now[0]) < Number(end[0])){
        const diffnow = Number(now[0]) - Number(start[0])
        if(diffnow < diff || diff === 0){
        diff = Number(now[0]) - Number(start[0])
        this.validshifts=[];
        this.validshifts.push(ele)}
      }
    })
    this._changeDetectorRef.markForCheck();
   }
   
   getro(){
    let q = {"ro_code": String(this.ro_code)}
    this.api.doRequest(`/api/ro_onboard/pull_romaster`, 'post', q).subscribe((result: any) => {
      if(result && result.data && result.data.length>0 && result.status == 'success'){
        this.postData.ro_master_ref={
          id:result.data[0]._id,
          ro_name:result.data[0].ro_name,
          ro_code:result.data[0].ro_code,
          client_name:result.data[0].client_name,
        }
        this._changeDetectorRef.markForCheck();
      }
    })
}

  filternozzle(){
    this.nozzlelist=[]
    this.selectedDu.forEach( ele =>{
      const dus= this.nozzleref.filter(x => (ele.du_id).includes(x.du_master_ref.du_id))
      this.nozzlelist=[...dus, ...this.nozzlelist]
    })
      this._changeDetectorRef.markForCheck();
  }

  getNozzle(){
      let q = {"ro_code": this.ro_code}
      this.api.doRequest(`/api/ro_onboard/pull_nozzlemaster`, 'post', q).subscribe((result: any) => {
        let nozzzles:any=[];
        if(result && result.data && result.data.length>0){
          this.nozzleref= JSON.parse(JSON.stringify(result.data))
        }
        this._changeDetectorRef.markForCheck();
      })

  }

  getdu(){
      let q = {"ro_code": this.ro_code}
      this.api.doRequest(`/api/ro_onboard/pull_dumaster`, 'post', q).subscribe((result: any) => {
        let dus:any=[];
        if(result && result.data && result.data.length>0){
          this.dulist= JSON.parse(JSON.stringify(result.data ))
          this._changeDetectorRef.markForCheck();
        }
      })
  }
 
    send(): void
    {
      if(this.endshift !== 'Yes'){
      this.postData.user_ref.shift=[];
      this.postData.user_ref.shift = {
        "shift_name": this.selectedShift.shift_name,
        "shift_id": this.selectedShift.shift_id,
        "shift_open_time": this.selectedShift.shift_open_time,
        "shift_close_time": this.selectedShift.shift_close_time
      }
      this.postData.du_master_ref=[];
      this.selectedDu.forEach(du =>{
        this.postData.du_master_ref.push({
          "id":du._id,
          "du_id":du.du_id
        })  
      })
      this.postData.nozzle_master_ref=[];
      this.selectedNozzle.forEach(du =>{
        this.postData.nozzle_master_ref.push({
          "id":du._id,
          "nozzle_id":du.nozzle_id
        })  
      })
      this.postData.login_time=moment().format('DD/MM/YYYY HH:mm:ss')
      this.postData.status='Open'
      this.postData.logout_time=''
      this.api.doRequest(`/api/shift_log`, 'post', this.postData).subscribe((result: any) => {
        if(result && result._id){
          this.toast.sToast('success', `Dear ${this.userdetails.name}, your Shift started successfully`)
        }
        else{
          this.toast.sToast('error', 'Something went wrong, please try again')
        }
        this.matDialogRef.close()
      }, error =>{
        console.log("error", JSON.stringify(error))
      })}
      else{
        if(this.logout === 'Yes'){
        this.updateshift();}
        else{
          this.matDialogRef.close()
        }  
      }

      
    }

    updateshift(){
      this.postData.logout_time=moment().format('DD/MM/YYYY hh:mm:ss')
      this.postData.status = 'Closed'
      this.api.doRequest(`/api/shift_log`, 'put', this.postData).subscribe((result: any) => {
        if(result && result._id){
          this.toast.sToast('success', `Dear ${this.userdetails.name}, your Shift closed successfully`)
        }
        else{
          this.toast.sToast('error', 'Something went wrong, please try again')
        }
        this.matDialogRef.close()
      }, error =>{
        console.log("error", JSON.stringify(error))
      })
    }

    saveAndClose(){
      this.matDialogRef.close();
    }

    ngOnDestroy(): void {
      this.selectedDu=[];
      this.selectedNozzle=[];
      this.dulist=[];
      this.nozzlelist=[];
      this.userdetails=[];
      this.ro_code='';
    }
}
