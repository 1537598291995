/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'summary',
    //     title: 'Summary',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'summarize',
    //     link : '/summary'
    // },

    // {
    //     id   : 'assets',
    //     title: 'Assets',
    //     type : 'basic',
    //     icon : 'heroicons_outline:collection',
    //     link : '/assets'
    // },
    {
        id   : 'status',
        title: 'Summary',
        type : 'basic',
        icon : 'heroicons_outline:view-grid-add',
        link : '/status'
    },
    {
        id   : 'retail-outlets',
        title: 'Retail Outlet',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:office-building',
        link : '/retail-outlets'
    },
   
    // {
    //     id   : 'du-transactions',
    //     title: 'Transactions',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'double_arrow',
    //     link : '/du-transactions'
    // },
    {
        id   : 'pricing',
        title: 'Pricing',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'price_check',
        link : '/pricing'
    },
    // {
    //     id   : 'shifts',
    //     title: 'Shifts',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'access_time',
    //     link : '/shifts'
    // },
    {
        id   : 'shiftsLogs',
        title: 'Shifts',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'access_time',
        link : '/shifts/shifts'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:users',
        link : '/users'
    },
    // {
    //     id   : 'audit-log',
    //     title: 'Shift Log',
    //     type : 'basic',
    //     icon : 'sync',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     link : '/audit-log'
    // },
    {
        id   : 'audit',
        title: 'Audit Log',
        type : 'basic',
        icon : 'sync',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link : '/audit-log/audit'
    },
    {
        id   : 'alert',
        title: 'Alerts',
        type : 'basic',
        icon : 'feather:alert-triangle',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link : '/alerts'
    },
    {
        id   : 'reports',
        title: 'Reports',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'feather:file',
        link : '/reports'
    },
    // {
    //     id   : 'romap',
    //     title: 'RO Map',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/reports/romap'
    // },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:cog',
        link : '/settings'
    },
    {
        id   : 'version',
        title: 'Versions',
        type : 'collapsable',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'upgrade',
        link : '/version',
        children:[
            {
                id   : 'software',
                title: 'Software',
                type : 'basic',
                // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
                icon : 'system_update',
                link : '/version/software'
            },
            {
                id   : 'hardware',
                title: 'Hardware',
                type : 'basic',
                // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
                icon : 'hardware',
                link : '/version/hardware'
            },
        ]
        
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation
/*export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];*/
